import styled from 'styled-components';

export const Container = styled.div`
  z-index: 3;
  position: relative;  
`;

export const MenuNavigationBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 9.903vw;
  height: 19.06vw;
  background-color: #6B969D;
  z-index: 1;
`;


export const Title = styled.div` 
  font-family: SilverSouthSerif;
  font-size: 8.125vw;
  color: #FFFFFF;
  letter-spacing: 0.6250vw;
  line-height: 9.375vw;
  text-transform: uppercase;
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TitleImg = styled.img`
  height: 2.188vw;
  align-self: flex-end;
  margin-bottom: 1.250vw;
`;

export const HamburgerMenuContainer = styled.div`
  height: 8.750vw;
  width: 8.750vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HamburgerMenu = styled.div`
  width: 21px;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: ${props => props.open ? '6px' : '0px'};
    width: ${props => props.open ? '0%' : undefined};
    left: ${props => props.open ? '50%' : undefined};
  }

  span:nth-child(2) {
    top: 6px;
    -webkit-transform: ${props => props.open ? 'rotate(45deg)' : undefined};
    -moz-transform: ${props => props.open ? 'rotate(45deg)' : undefined};
    -o-transform: ${props => props.open ? 'rotate(45deg)' : undefined};
    transform: ${props => props.open ? 'rotate(45deg)' : undefined};
  }

  span:nth-child(3) {
    top: ${props => props.open ? '6px' : '12px'};
    -webkit-transform: ${props => props.open ? 'rotate(-45deg)' : undefined};
    -moz-transform: ${props => props.open ? 'rotate(-45deg)' : undefined};
    -o-transform: ${props => props.open ? 'rotate(-45deg)' : undefined};
    transform: ${props => props.open ? 'rotate(-45deg)' : undefined};
  }

 span:nth-child(4) {
  top: ${props => props.open ? '6px' : '12px'};
  width: ${props => props.open ? '0%' : undefined};
  left: ${props => props.open ? '50%' : undefined};
}
`;