/* eslint-disable no-nested-ternary */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core';
//actions
const styles = { paper: { height: 'auto' } };

function Modal({
  hideModal, showModal, modalComponent, classes,
}) {
  return (
    <Dialog classes={classes} maxWidth={'none'} disableRestoreFocus={true} onClose={hideModal} open={showModal}>
      <DialogContent style={{ padding: 0 }}>
        {modalComponent}
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(Modal);
