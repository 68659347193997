import React from 'react';
import { Spinner, Container } from './styled';

function Loader({ show, style }) {
  const height = typeof(window) !== 'undefined' ? window.innerHeight + 'px' : '100vh';
  return (
    <Container height={height} show={show} style={style}>
      <Spinner />
    </Container>
  );
}

export default Loader;