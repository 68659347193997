import React from 'react';
import { HamburgerMenuContainer, HamburgerMenu } from './styled';


function Menu({ onClick, open }) {
  return (
    <HamburgerMenuContainer onClick={onClick}>
      <HamburgerMenu open={open}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </HamburgerMenu>
    </HamburgerMenuContainer>
  );
}

export default Menu;
