import styled from 'styled-components';

export const Container = styled.div`
  background-color: #E1CFCD;
  height: 50px;
  padding: 0 8.696vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 3.382vw;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
`;