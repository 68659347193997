import React from 'react';
import { navigate } from '@reach/router';

import {
  Container, Col, Item, Title, Logo,
} from './styled';
import HeartIcon from './HeartIcon.svg';

export default function Section2({ sections }) {
  return (
    <Container>
      {
        sections.map(({ primary:{ header }, fields }, index) => (
          <>
            {index === 2 && (
              <Col key={header}>
                <Logo>
                  <div>So beautiful. So confident</div>
                  <div>SO BRIDELY<img src={HeartIcon} /></div>
                </Logo>
              </Col>
            )}
            <Col key={header}>
              <Title>{header}</Title>
              {
                fields.map(({ link, label }) => (
                  <Item key={label} onClick={() => navigate(link)}>{label}</Item>
                ))
              }
            </Col>
          </>
        ))
      }
    </Container>
  );
}

{/* <Logo>
<div>So beautiful. So confident</div>
<div>SO BRIDELY<img src={HeartIcon} /></div>
</Logo> */}
