import React from 'react';
import { connect } from 'react-redux';
import Row from '../../../Row';
import Image from '../../../ImageBasic';
import Input from '../../../ThemedInput';
import { subscribeUserToNewletter } from '../.././../../actions/newsletterActions';
import white_arrow_right from './white_arrow_right.svg';
import {
  Container, Title, ImageContainer,
} from './styled';

function SignUp() {
  return (
    <Container>
      <Title>Sign Up To Our Email List</Title>
      <Row style={{
        marginTop: 82 , height: 50, width: 'auto',
      }}
      >
        <Input style={{
          marginRight: 30, height: 50, width: 360,
        }} label="Full Name"
        />
        <Input style={{
          height: 50, marginRight: 30, width :360,
        }} label="Email"
        />
        <ImageContainer>
          <Image src={white_arrow_right} />
        </ImageContainer>
      </Row>
    </Container>
  );
}

const mapDispatchToProps = { subscribeUserToNewletter };

export default connect(null, mapDispatchToProps)(SignUp);
