import * as types from './actionTypes';

export function showModal(modalComponent, _options) {
  const modalOptions = {
    onModalHide: null,
    showClose: true,
    disableBackdropClick: false,
    fullscreen: false,
    style: { margin: '0 40px 40px' },
    ..._options,
  };
  return {
    type: types.SHOW_MODAL,
    modalComponent,
    modalOptions,
    showModal: true,
  };
}

export function hideModal() {
  return { type: types.SHOW_MODAL, showModal: false };
}
