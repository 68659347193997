import styled from 'styled-components';

export const Container = styled.div`
  background-color: #F3F0EB;
  height: 489px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: SilverSouthScript;
  font-size: 109px;
  color: #124C56;
  text-align: center;
`;

export const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7DBEC8;
  flex-shrink: 0;
`;