import styled from 'styled-components';

export const Spinner = styled.div`
	height: 80px;
	width: 80px;
	background: #FE95A2;
  border-radius: 50%;
	border: 2px solid #F4F5FA;
	border-bottom-color: #D47883;
	border-top-color: #D47883;
	animation: spinner-animation 800ms ease infinite;
@keyframes spinner-animation {
	to {
		transform: rotate(360deg);
	}
}
`;

export const Container = styled.div`
	position: fixed;
	z-index: 2;
	background-color: white;
	height: ${props => props.height};
	width: 100vw;
	justify-content: center;
	align-items: center;
	opacity: ${props => (props.show ? 1 : 0)};
  display: ${props => (props.show ? 'flex' : 'none')};
`;