import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container } from './styled';
//helpers
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';

const staticQuery = graphql`
query {
  prismic {
    allFooter_menus {
      edges {
        node {
          title
          body {
            ... on PRISMIC_Footer_menuBodyMenu_items {
              type
              label
              primary {
                header
              }
              fields {
                label
                link
              }
            }
          }
        }
      }
    }
  }
 }`;

const Footer = ({ showSignUp = true }) => (
  <StaticQuery
    query={staticQuery}
    render={_data => {
      const data = _data?.prismic?.allFooter_menus.edges[0]?.node;
      return (
        <Container>
          {showSignUp && <Section1 />}
          <Section2 sections={data?.body}/>
          <Section3 />
        </Container>
      );
    }}
  />
);

export default Footer;
