import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 5.875vw 0;
`;

export const Col = styled.div`
  margin-right: 2.813vw;
  :last-child {
    margin-right: 0;
  }
`;

export const Item = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.000vw;
  color: #464855;
  text-align: left;
  line-height: 26px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 1.125vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0.5000vw;
  text-transform: uppercase;
`;

export const Logo = styled.div`
  margin: 0 5.500vw;
  color: #464855;
  > div:nth-child(1) {
    font-family: SilverSouthScript;
    font-size: 2.500vw; 
    letter-spacing: 0;
    text-align: center;
  }
  > div:nth-child(2) {
    font-family: SilverSouthSerif;
    font-size: 3.750vw;
    letter-spacing: 0;
    text-align: center;
    img {
      height: 0.7500vw;
      width: 0.8750vw;
      object-fit: contain;
      align-self: flex-end;
    }
  }
`;