import React from 'react';
import { navigate } from '@reach/router';
import { Container } from './styled';

function NavLogo({
  style, color = '#FFFFFF', topStyle, bottomStyle,
  top = 'So beautiful. So confident', bottom = 'SO BRIDELY',
  heart = true,
}) {
  return (
    <Container onClick={() => navigate('/')} style={style}>
      <div style={{ color: color || style.color, ...topStyle }}>{top}</div>
      <div style={{
        textTransform: 'uppercase',
        whiteSpace: 'nowrap', color: color || style.color, flexShrink: 0, ...bottomStyle,
      }}>{bottom}{heart && <HeartLogo color={color || style.color} />}</div>
    </Container>
  );
}

function HeartLogo({ color }) {
  return (
    <svg width="14px" height="13px" viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop---1600w" transform="translate(-988.000000, -132.000000)" fill={color} fillRule="nonzero">
          <g id="NavBar">
            <g id="logo" transform="translate(570.000000, 38.000000)">
              <g id="So-Bridely" transform="translate(0.000000, 45.000000)">
                <g id="heart" transform="translate(418.000000, 49.000000)">
                  <path d="M10.15,2.25691873e-06 C12.2744924,0.00162571495 13.9967841,1.74152431 14,3.88936052 C14,5.83846273 13.08125,7.58705216 11.1095833,9.39166721 C9.87043419,10.4800427 8.54687924,11.4661144 7.15166667,12.3403846 L7,12.3846154 L6.85125,12.3403846 C5.4434488,11.4823017 4.11662026,10.4952459 2.8875,9.39166721 C0.97125,7.58410344 0,5.7323089 0,3.88936052 C0.00247209683,2.19285615 1.09153872,0.69338369 2.69188483,0.183060962 C4.29223093,-0.327261766 6.03518234,0.269129061 7,1.65718144 C7.7189424,0.617445416 8.89518752,-0.00136376892 10.15,2.25691873e-06 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}

export default NavLogo;
