import { makeRequest, API_URL } from '../helpers/Fetch';
import { setLoading } from './loadingActions';

export function subscribeUserToNewletter(email, name) {
  return async dispatch => {
    try {
      const payload = {
        name,
        email,
      };
      dispatch(setLoading(true));
      await makeRequest(API_URL + 'newsletter', payload)
        .setMethod('POST')
        .send();
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}