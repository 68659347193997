import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  formatNumberInput, updatePhoneNumberInput, updateCCInput,
  updateCCExp,
} from '../../helpers/Format/numbers.js';
import {
  Container, Label, Input, ChildContainer,
} from './styled.js';
import ValidatedIcon from './ValidatedIcon.svg';
export { ValidatedIcon };

function ThemedInput({
  label = '', type: _type, update, value,
  required, pattern, labelStyle, max,
  children, disabled, placeholder, inputProps, name, inputStyle,
  validate = () => false,
  ...other
}) {
  const isMobile = useMediaQuery('(max-width: 500px)');
  let inputRef;
  let type = _type;

  if (_type === 'amount' || (_type === 'number' && isMobile) || (_type === 'phoneNumber' && isMobile)) {
    type = 'tel';
  }

  if (_type === 'amount') {
    value = `$${formatNumberInput(value)}`;
  }

  if (max && value) {
    value = value.substr(0, max);
  }

  const validated = validate(value || '');
  return (
    <Container {...other}>
      <Label shrink={label.length > 10} style={labelStyle}>{label}</Label>
      {!children ? (<Input
        style={inputStyle}
        {...inputProps}
        placeholder={placeholder}
        disabled={disabled}
        ref={ref => {
          inputRef = ref;
        }}
        onChange={({ target: { value } }) => {
          let valueAfterMask = value;

          if (_type === 'amount') {
            valueAfterMask = formatNumberInput(value);
          }

          if (_type === 'phoneNumber') {
            valueAfterMask = updatePhoneNumberInput(value);
          }

          if (_type === 'cc') {
            valueAfterMask = updateCCInput(value);
          }

          if (_type === 'cc-exp') {
            valueAfterMask = updateCCExp(value);
          }

          inputRef.value = valueAfterMask;

          if (update) {
            update(valueAfterMask);
          }
        }}
        autoComplete="on"
        name={name || label.toLowerCase().trim()}
        pattern={pattern}
        type={type}
        required={required}
        value={value}
        id={label}
      />) :
        <ChildContainer disabled={disabled}>
          {children}
        </ChildContainer>
      }
      {validated && <img src={ValidatedIcon} />}
    </Container>
  );
}

export default ThemedInput;