import styled from 'styled-components';

export const Container = styled.div`
  height: 193px;
  background-color: #6B969D;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CartItems = styled.div`
  background-color: #E67A8E;
  height: 0.7500vw;
  width: 0.7500vw;
  border-radius: 0.3750vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.4375vw;
  color: #FFFFFF;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -18%;
  right: -44%;
`;

export const HomeImage = styled.img`
  height: 16px;
  width: 16px;
  object-fit: contain;
  cursor: pointer;
`;

export const CartImage = styled.img`
  height: 16;
  object-fit: contain;
  cursor: pointer;
`;