import React, {
  useEffect, useCallback, useState,
} from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import MediaQuery from 'react-responsive';
import DesktopNavigationMenu from '../../components/Desktop/Navigation';
import MobileNavigationMenu from '../../components/Mobile/Navigation';
import DesktopFooter from '../Desktop/Footer';
import MobileFooter from '../Mobile/Footer';
import Modal from '../Modal';
import { DEVICE_QUERIES } from '../../constants';
import Loader from '../Loader';
import AppProvider from '../../components/AppProvider';

function Layout(props) {
  const [width, setWidth] = useState(null);

  const updateWidth = useCallback(() => {
    if (typeof window !== 'undefined' && window.$) {
      setWidth($(window).width());
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.$) {
      $(window).on('resize', updateWidth);
    }
    return () => {
      if (typeof window !== 'undefined' && window.$) {
        $(window).off('resize', updateWidth);
      }
    };
  }, []);

  const height = typeof window !== 'undefined' ? window.innerHeight : '100vh';
  const _width = width ? width : '100vw';
  const name = 'SoBridely';
  const {
    desktop, mobile, showFooter = true,
    loading, metaTags, pageData, data,
  } = props;
  console.log('data', data);
  const {
    title = 'So Bridely', description = 'Find your dream wedding dress', image, url,
  } = metaTags || {};
  return (
    <StaticQuery
      query={query}
      render={(
        data
      ) => (
        <AppProvider pageData={{ ...pageData, ...data.prismic }}>
          <Helmet
            title={title}
            meta={[
              { name: 'description', content: description },
              { name: 'keywords', content: 'sobridely' },
              { itemProp: 'name', content:name },
              { itemProp: 'description', content: description },
              { itemProp: 'image', content: image },
              { property: 'og:url', content: url } ,
              { property: 'og:type', content: 'website' },
              { property: 'og:title', content: name },
              { property: 'og:description', content: description },
              { property: 'og:type', content: image },
              { name: 'twitter:card', content: 'summary_large_image' },
              { name: 'twitter:title', content: name },
              { name: 'twitter:description', content: description },
              { name: 'twitter:image', content: image },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <div style={{
            height,
            width: _width,
          }} className="fade-in">
            <Modal />
            <Loader show={loading} />
            <MediaQuery query={DEVICE_QUERIES.desktop}>
              <DesktopNavigationMenu />
              {desktop}
              {showFooter && <DesktopFooter />}
            </MediaQuery>
            <MediaQuery query={DEVICE_QUERIES.mobile}>
              <MobileNavigationMenu />
              {mobile}
              {showFooter && <MobileFooter />}
            </MediaQuery>
          </div>
        </AppProvider>
      )} />
  );
}

const mapStateToProps = (state) => ({ ...state.loaderReducer, ...state.homeReducer });

Layout.propTypes = { children: PropTypes.node.isRequired };

export default connect(mapStateToProps)(Layout);

const query = graphql`
  query {
    prismic {
      color_types(lang: "en-us", uid: "shop") {
        body {
          ... on PRISMIC_Color_typesBodyColors {
            fields {
              name
            }
          }
        }
      }
      condition_types(lang: "en-us", uid: "shop") {
        body {
          ... on PRISMIC_Condition_typesBodyConditions {
            fields {
              name
            }
          }
        }
      }
      designer_types(lang: "en-us", uid: "shop") {
        body {
          ... on PRISMIC_Designer_typesBodyDesigners {
            fields {
              name
            }
          }
        }
      }
      footer_menu(uid: "home", lang: "en-us") {
        body {
          ... on PRISMIC_Footer_menuBodyMenu_items {
            primary {
              header
            }
            fields {
              label
              link
            }
          }
        }
      }
      material_types(lang: "en-us", uid: "shop") {
        body {
          ... on PRISMIC_Material_typesBodyMaterial_types {
            fields {
              name
            }
          }
        }
      }
      navigation_menu(lang: "en-us", uid: "home") {
        body {
          ... on PRISMIC_Navigation_menuBodyNavigaion_menu_item {
            primary {
              link
              header
            }
            fields {
              label
              link
            }
          }
        }
      }
      silhouette_types(lang: "en-us", uid: "shop") {
        body {
          ... on PRISMIC_Silhouette_typesBodySilhouettes {
            fields {
              name
            }
          }
        }
      }
      sleeve_types(lang: "en-us", uid: "shop") {
        body {
          ... on PRISMIC_Sleeve_typesBodySleeves {
            fields {
              name
            }
          }
        }
      }
      neckline_types(uid: "shop", lang: "en-us") {
        body {
          ... on PRISMIC_Neckline_typesBodyNecklines {
            fields {
              name
            }
          }
        }
      }
    }
  }
`;