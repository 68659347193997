/* eslint-disable no-nested-ternary */
import { connect } from 'react-redux';
import React from 'react';
import { hideModal } from '../../actions/modalActions';
import Modal from './Modal';

function ModalContainer(props) {
  return <Modal {...props} />;
}

const mapStateToProps = state => ({ ...state.modalReducer });

const mapDispatchToProps = { hideModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalContainer);
