import React, { Component } from 'react';
import { Container, Name } from './styled';

export default class Section2 extends Component {
  render() {
    return (
      <Container>
        <Name>So Bridely © 2019</Name>
        <Name>© © © ©</Name>
      </Container>
    );
  }
}
