import styled from 'styled-components';

export const Container = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 10px;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  text-decoration: none;
  flex-shrink: 0;
`;

export const DropdownLink = styled.a`
  -webkit-appearance: none;
  text-decoration: none;
  cursor: pointer;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  margin-bottom: 15px;
`;

export const DownArrow = styled.img`
  margin-left: 10px;
`;

export const DropdownContainer = styled.div`
  z-index: 2;
  position: absolute;
  width: 186px;
  opacity: ${props => props.active ? 1 : 0};
  top: 1.2vw;
  transform: translate(-50%, 0%);
  left: 50%;
  display: flex;
  flex-direction: column;
  visibility: ${props => props.active ? 'visible' : 'hidden'};
  transition: opacity .3s ease-in-out 0s, visibility .3s ease-in-out 0s;
`;

export const DropDownBox = styled.div`
  background-color: #52767C;
  display: flex;
  flex-direction: column;
  padding-top: 43px;
  padding-bottom: 30px;
  border-bottom: 3px solid #304E53;
  :last-child {
    margin-bottom: 0;
  }
`;