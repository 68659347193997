import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import heart_icon from '../../../images/heart_icon.svg';
import {
  MenuNavigationBar, Container, TitleContainer, Title, TitleImg,
} from './styled';
import HamburgerMenu from './HamburgerMenu';
import ExpandedMenu from './ExpandedMenu/';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  render() {
    const { navigation_menu_title = 'So Bridely', accountType } = this.props;
    return (
      <Container>
        <MenuNavigationBar>
          <TitleContainer onClick={() => navigate('/')}>
            <Title>{navigation_menu_title}</Title>
            <TitleImg src={heart_icon} />
          </TitleContainer>
          <HamburgerMenu
            open={this.state.open}
            onClick={() => this.setState({ open: !this.state.open })}
          />
        </MenuNavigationBar>
        <ExpandedMenu close={() => this.setState({ open: false })} accountType={accountType} open={this.state.open} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.userReducer });

export default connect(mapStateToProps)(Navigation);