import React from 'react';
import { Container } from './styled';
//helpers
import Section1 from './Section1';
import Section2 from './Section2';

const Footer = () => (
  <Container>
    <Section1 />
    <Section2 />
  </Container>
);

export default Footer;
