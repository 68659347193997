import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 5.500vw;
    cursor: pointer;
  > div:nth-child(1) {
    font-family: SilverSouthScript;
    font-size: 2.500vw;
    color: inherit;
    letter-spacing: 0;
    text-align: center;
  }
  > div:nth-child(2) {
    font-family: SilverSouthSerif;
    font-size: 3.750vw;
    color: inherit;
    letter-spacing: 0;
    text-align: center;
    svg {
      height: 0.7500vw;
      width: 0.8750vw;
      object-fit: contain;
      align-self: flex-end;
    }
  }
`;