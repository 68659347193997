import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { showModal, hideModal } from '../../actions/modalActions';
import { setLoading } from '../../actions/loadingActions';
import { getPageQueriesFromPath } from './helpers';

function getTypesByName(data, key) {
  return data[key]?.body[0].fields.map(({ name }) => name);
}

export const AppContext = React.createContext({});

function AppProvider({
  children, showModal, hideModal, setLoading, pageData,
}) {
  const [pageQueries, setPageQueries] = useState({});
  const url = typeof(window) !== 'undefined' ? window.location : '';

  useEffect(() => {
    const _pageQueries = getPageQueriesFromPath(url);
    setPageQueries(_pageQueries);
  }, [url]);

  const deviceType = useMediaQuery('(max-width:500px)') ? 'mobile' : 'desktop';
  const colorTypes = getTypesByName(pageData, 'color_types');
  const conditionTypes = getTypesByName(pageData, 'condition_types');
  const designerTypes = getTypesByName(pageData, 'designer_types');
  const materialTypes = getTypesByName(pageData, 'material_types');
  const silhouetteTypes = getTypesByName(pageData, 'silhouette_types');
  const sleeveTypes = getTypesByName(pageData, 'sleeve_types');
  const styleNameTypes = getTypesByName(pageData, 'style_name_types');
  const necklineTypes = getTypesByName(pageData, 'neckline_types');
  const state = {
    actions: {
      showModal, hideModal, setLoading,
    },
    state: {
      pageQueries, deviceType,
      conditionTypes, colorTypes,
      designerTypes, materialTypes,
      silhouetteTypes, sleeveTypes, styleNameTypes,
      necklineTypes,
    },
    data: pageData,
  };
  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
}

const mapDispatchToProps = {
  showModal, hideModal, setLoading,
};

export default connect(null, mapDispatchToProps)(AppProvider);