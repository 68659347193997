import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 2px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  img {
    position: absolute;
    right: 12px;
    top: 59%;
    width: 15px;
    object-fit: contain;
    }
`;

export const ChildContainer = styled.div`
  background: ${props => props.disabled ? '' : '#F2F8F9'};
  border: ${props => props.disabled ? 'none' : '1px solid #7DBEC8'};
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  font-family: sofia-pro;
  font-weight: 400;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 0.5px;
  line-height: 16px;
  padding: 0 10px;
  padding: 10px;
  text-align: left;
  -webkit-appearance: none;
  border-radius: 0 !important;
`;

export const Input = styled.input`
  background: ${props => props.disabled ? '' : '#F2F8F9'};
  border: ${props => props.disabled ? 'none' : '1px solid #7DBEC8'};
  width: calc(100% - 20px);
  font-family: sofia-pro;
  font-weight: 400;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 0.5px;
  line-height: 16px;
  padding: 0 10px;
  padding: 10px;
  text-align: left;
  height: 24px;
  -webkit-appearance: none;
  ::placeholder {
    color: #124C56;
  }
  border-radius: 0 !important;
`;

export const Label = styled.div`
  font-family: sofia-pro;
  font-weight: 400;
  font-size: 12px;
  color: #6B969D;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 12px;
`;