import React from 'react';
import styled from 'styled-components';

const Container = styled.img`

`;

function Image(props) {
  return (
    <Container {...props} />
  );
}

export default Image;