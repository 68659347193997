import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { signOut } from '../../../../helpers/Firebase';
import {
  Container, MenuItem, CartImage, DropdownContainer, CartItems,
} from './styled';
import DownArrow from './DownArrow.svg';
import UpArrow from './UpArrow.svg';
import CartIcon from './CartIcon.svg';

class ExpandedMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_buy: false,
      show_account: false,
      show_sell: false,
    };
  }
  render() {
    const { user, checkoutItems = [] } = this.props;
    return (
      <Container open={this.props.open}>
        <MenuItem onClick={() => this.setState({ show_buy: !this.state.show_buy })} >
          <span>Buy</span>
          <img src={this.state.show_buy ? UpArrow : DownArrow} />
          <DropdownContainer open={this.state.show_buy}>
            <div onClick={() => {
              navigate('/shop');
              this.props.close();
            }}>All Dresses</div>
            <div onClick={() => {
              navigate('/shop?search=recent');
              this.props.close();
            }}>Recently Added</div>
            <div onClick={() => {
              navigate('/shop?search=boutique');
              this.props.close();
            }}>Shop by Boutiques</div>
          </DropdownContainer>
        </MenuItem>
        <MenuItem onClick={() => this.setState({ show_sell: !this.state.show_sell })} >
          <span>Sell</span>
          <img src={this.state.show_sell ? UpArrow : DownArrow} />
          <DropdownContainer open={this.state.show_sell}>
            <div onClick={() => {
              navigate('/create-account/seller');
              this.props.close();
            }}>For Brides</div>
            <div onClick={() => {
              navigate('/create-account/seller#boutique');
              this.props.close();
            }}>For Boutiques</div>
          </DropdownContainer>
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/how-it-works');
          this.props.close();
        }} >
          <span>How It Works</span>
        </MenuItem>
        {/* <MenuItem onClick={() => navigate('/blog')} >
          <span>Blog</span>
        </MenuItem> */}
        <MenuItem onClick={() => {
          navigate('mailto:help@sobridely.com');
          this.props.close();
        }}>
          <span>Help</span>
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/about-us');
          this.props.close();
        }}>
          <span>About Us</span>
        </MenuItem>
        {user ?
          <>
            <MenuItem onClick={() => this.setState({ show_account: !this.state.show_account })} >
              <span>My Account</span>
              <img src={this.state.show_account ? UpArrow : DownArrow} />
              <DropdownContainer open={this.state.show_account}>
                <div onClick={() => {
                  navigate('/dashboard#profile');
                  this.props.close();
                }}>Profile</div>
                <div onClick={() => {
                  navigate('/dashboard#inbox');
                  this.props.close();
                }}>Inbox</div>
                {this.props.accountType !== 2 && <div onClick={() => {
                  navigate('/dashboard#notifications');
                  this.props.close();
                }}>Notifications</div>}
                <div onClick={() => {
                  navigate('/dashboard#fitting-room');
                  this.props.close();
                }}>Fitting Room</div>
                {this.props.accountType !== 2 && <div onClick={() => {
                  navigate('/dashboard#my-listings');
                  this.props.close();
                }}>My Listings</div>}
                <div onClick={() => {
                  navigate('/dashboard#orders');
                  this.props.close();
                }}>Order History</div>
              </DropdownContainer>
            </MenuItem>
            <MenuItem onClick={signOut}>
              <span>Logout</span>
            </MenuItem>
          </>
          : <MenuItem onClick={() => {
            navigate('/login');
            this.props.close();
          }}>
            <span>Login</span>
          </MenuItem>}
        <div style={{ position: 'relative' }}>
          <CartImage onClick={() => {
            navigate('/checkout');
            this.props.close();
          }} src={CartIcon} />
          {Object.keys(checkoutItems).length ? <CartItems>{Object.keys(checkoutItems).length}</CartItems> : null}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.userReducer,
  ...state.shopReducer,
});

export default connect(mapStateToProps)(ExpandedMenu);
