import React, { Component } from 'react';
import { navigate } from '@reach/router';
import {
  Container, Col, Item, Title,
} from './styled';

export default class Section1 extends Component {
  render() {
    return (
      <Container>
        <Col>
          <Title>BUYING</Title>
          <Item onClick={() => navigate('/shop')}>All Dresses</Item>
          <Item onClick={() => navigate('/shop?search=recent')}>Recently Added</Item>
          <Item onClick={() => navigate('/shop')}>Shop by Style</Item>
        </Col>
        <Col>
          <Title>Selling</Title>
          <Item onClick={() => navigate('/create-account/seller')}>For Brides</Item>
          <Item onClick={() => navigate('/create-account/seller#boutique')}>For Boutiques</Item>
        </Col>
        <Col>
          <Title>HELP</Title>
          <Item onClick={() => navigate('mailto:help@sobridely.com')}>Contact</Item>
          <Item onClick={() => navigate('/faq')}>FAQs</Item>
        </Col>
        <Col>
          <Title>LEGAL</Title>
          <Item onClick={() => navigate('/privacy-policy')}>Privacy Policy</Item>
          <Item onClick={() => navigate('/terms-of-service')}>Terms & Conditions</Item>
          <Item onClick={() => navigate('/copyright')}>Copyright</Item>
        </Col>
      </Container>
    );
  }
}
