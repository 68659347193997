import React, { useState } from 'react';
import DownArrowIcon from '../DownArrow.svg';
import Triangle from '../Triangle.svg';
import Flexor from '../../../Flexor';
import {
  Container, DropdownContainer, DropDownBox,
  DownArrow, DropdownLink,
} from './styled.js';

function MenuItem({
  expandedOptions, label, link,
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Flexor flex="0 1 43px" />
      <Container as={link ? 'a' : null} href={link} onClick={link ? null : () => setExpanded(!expanded)}>
        <span>{label}</span>
        {expandedOptions &&
          <>
            <DownArrow src={DownArrowIcon} />
            <DropdownContainer active={expanded}>
              <img src={Triangle} />
              <DropDownBox>
                {
                  expandedOptions.map(({
                    label, link, action,
                  }) =>
                    <DropdownLink onClick={action ? action : null}
                      href={link}
                      key={link}>
                      {label}
                    </DropdownLink>)
                }
              </DropDownBox>
            </DropdownContainer>
          </>
        }
      </Container>
    </>
  );
}

export default MenuItem;