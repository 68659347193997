import styled from 'styled-components';

export const CartItems = styled.div`
  background-color: #E67A8E;
  height: 6.522vw;
  width: 6.522vw;
  border-radius: calc(6.522vw / 2);
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 4.106vw;
  color: #FFFFFF;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -18%;
  right: -44%;
`;

export const Container = styled.div`
  width: 100%;
  transform: ${props => props.open ? 'translate(0, 0)' : 'translate(0, -120%)'};
  visibility: ${props => props.open ? 'visible' : 'hidden'};
  transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
  top: 100%;
  z-index: -1;
  position: absolute;
  display: flex;
  left: 0;
  background-color: #52767C;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6.763vw;
`;

export const MenuItem = styled.div`
  width: 100%;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 5.314vw;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 50px;
  position: relative;
  img {
    margin-left: 2vw;
    top: 6vw;
    position: absolute;
  }
`;

export const CartImage = styled.img`
  height: 13.04vw;
  width: 14.25vw;
  object-fit: contain;
  margin-top: ${props => props.margin ? '4.831vw' : '0'};
`;

export const DropdownContainer = styled.div`
  width: 100%;
  height: ${props => props.open ? '100%' : '0'};
  transform: ${props => props.open ? 'translate(0, 0) scaleY(1)' : 'translate(0, -50%) scaleY(0)'};
  display: flex;
  transition: transform 0.2s ease-in-out, height 0.2s ease-in-out;
  background-color: #304E53;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;